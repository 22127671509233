/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import { readableColor } from "polished";
import Logo from "../icons/logo";
import useSiteMetadata from "@lekoarts/gatsby-theme-jodie/src/hooks/use-site-metadata";
import useJodieConfig from "@lekoarts/gatsby-theme-jodie/src/hooks/use-jodie-config";
import Navigation from "./navigation";
import { useState } from "react";
import { Transition } from "@headlessui/react";
type SidebarProps = { bg: string };

const Sidebar = ({ bg }: SidebarProps) => {
  const { siteTitle } = useSiteMetadata();
  const { basePath } = useJodieConfig();
  const [open, setOpen] = useState(false);

  return (
    <header
      sx={{
        p: [3, 3, 4],
        width: (t: any): any => [
          `100%`,
          `100%`,
          `100%`,
          t.sidebar.normal,
          t.sidebar.wide,
        ],
        backgroundColor: bg,
        position: [`relative`, `relative`, `relative`, `fixed`],
        height: `100%`,
        display: `flex`,
        flexDirection: [`row`, `row`, `row`, `column`],
        alignItems: [`center`, `center`, `center`, `flex-start`],
        justifyContent: [
          `space-between`,
          `space-between`,
          `space-between`,
          `flex-start`,
        ],
        svg: {
          fill: readableColor(bg),
        },
        variant: `sidebar`,
      }}
      data-testid="sidebar"
      className="flex justify-center"
    >
      <Link
        to={basePath}
        aria-label={`${siteTitle}, Back to Home`}
        sx={{ width: [`3rem`, `4rem`, `4.5rem`, `5rem`] }}
      >
        <Logo />
      </Link>
      <div sx={{ py: 4, display: [`none`, `none`, `none`, `block`] }} />
      <Navigation bg={bg} breakpoint="lg:block hidden" />
      <Transition
        show={open}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Navigation bg={bg} breakpoint="lg:hidden block" divider="|" />
      </Transition>
      <div className="block lg:hidden">
        <button
          className="ml-5 flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
          onClick={() => setOpen(!open)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
    </header>
  );
};

export default Sidebar;
