/** @jsx jsx */
import { jsx, Link, useThemeUI } from "theme-ui";
import { readableColor } from "polished";
import useSiteMetadata from "@lekoarts/gatsby-theme-jodie/src/hooks/use-site-metadata";
import { useColorMode } from "theme-ui";
import { Image } from "react-bootstrap";
import firma from "./firma.png";
const Footer = ({ bg }: { bg: string }) => {
  const { siteTitle } = useSiteMetadata();
  const {
    theme: { rawColors },
  } = useThemeUI();

  const text = readableColor(
    bg,
    rawColors!.textMuted as string | undefined,
    rawColors!.textMutedLight as string | undefined
  );
  const [colorMode, setColorMode] = useColorMode();

  const nextColorMode = colorMode === "tosh" ? "tosh" : "tosh";

  return (
    <footer
      sx={{
        position: [`relative`, `relative`, `relative`, `fixed`],
        width: (t: any): any => [
          `100%`,
          `100%`,
          `100%`,
          t.sidebar.normal,
          t.sidebar.wide,
        ],
        bottom: 0,
        color: text,
        fontSize: 0,
        p: [3, 3, 4],
        background: bg,
        a: {
          color: readableColor(bg),
          "&:hover,&:focus": {
            color: readableColor(bg, `primary`, `primaryLight`, false),
          },
        },
        variant: `footer`,
      }}
    >
      <div>
        <Image rounded src={firma} alt="firma" width={100} />
      </div>
      <div>Contatti</div>
      <div>
        <Link
          aria-label="Facebook"
          href="https://www.facebook.com/profile.php?id=100000588297690"
        >
          Facebook
        </Link>
        {` `}
        {` `}
        <Link
          aria-label="Instagram"
          href="https://www.instagram.com/mikicip66/"
        >
          Instagram
        </Link>{" "}
        <br></br>
        <Link href="mailto:michi.cipriani@libero.it">
          {" "}
          michi.cipriani@libero.it
        </Link>
        {/* <Link onClick={() => setColorMode(nextColorMode)}> Change color</Link> */}
      </div>
    </footer>
  );
};

export default Footer;
