import * as React from "react"
import avatar from './avatar.jpg'
const Logo = () => (
  <>
    <title>Michela Cipriani</title>
    <img src={avatar} width="200" height="200" style={{ "borderRadius": "50%"}}/ >
  </>
)

export default Logo
